import React from 'react'
import PresentationArchive from './presentation-archive'

import '../styles/presentation-archive-list.css'

function PresentationArchiveList (props) {
  return (
    <>
    <div className="presentation-archive-list wrapper">
      {props.title && <h3>{props.title}</h3>} 
      <div className="presentation-archive-holder">
        {props.nodes &&
          props.nodes.map(node => (
              <PresentationArchive {...node} />
          ))}
      </div>
    </div>
    </>
  )
}

PresentationArchiveList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default PresentationArchiveList