import {Link} from 'gatsby'
import {format} from 'date-fns'
import React from 'react'
import {buildImageObj, getPresentationUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import '../styles/presentation-archive.css'

function PresentationArchive (props) {

  const {image, title, publishedAt, slug, _rawExcerpt} = props

  //console.log(props);

  return (

    <div className="presentation-archive-item grid">

        <div className="width-12-12-m body-holder">

              <h2><Link to={getPresentationUrl(publishedAt, slug.current)}>{title}</Link></h2>

              <div className="date-tag-holder">
                <div class="date-holder">
                  <span className="date">{format(publishedAt, 'MMMM Do, YYYY')}</span>
                </div>
              </div>

              {image && image.desktopImage && image.desktopImage.asset && (
                <div className="image-holder">
                  <Link to={getPresentationUrl(publishedAt, slug.current)}>
                  <picture>
                    <source 
                      media="(max-width: 699px)" 
                      srcset={imageUrlFor(buildImageObj(image.mobileImage))
                        .width(1000)
                        .height(Math.floor((1 / 1) * 1000))
                        .auto('format')
                        .url()}
                    />
                    <source 
                      media="(min-width: 700px)" 
                      srcset={imageUrlFor(buildImageObj(image.desktopImage))
                        .width(2000)
                        .height(Math.floor((1 / 2) * 2000))
                        .auto('format')
                        .url()}
                    />
                    <img
                      src={imageUrlFor(buildImageObj(image.desktopImage))
                        .width(2000)
                        .height(Math.floor((1 / 2) * 2000))
                        .auto('format')
                        .url()}
                      alt={image.desktopImage.alt}
                      title={image.desktopImage.alt}
                    />
                  </picture>
                  </Link>
                  {image.desktopImage.caption && (
                    <span className="caption">
                      {image.desktopImage.caption}
                    </span>
                  )}

                </div>
              )}

              {_rawExcerpt && (
                  <>
                  {_rawExcerpt && <PortableText blocks={_rawExcerpt} />}
                  </>
              )}

            </div>

        </div>

  )
}

export default PresentationArchive